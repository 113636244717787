import clsx from 'clsx';

import Link from 'components/common/Link';
import Markdown from 'components/common/Markdown';
import ResponsiveImage from 'components/common/ResponsiveImage';

import { SingleLogoProps } from 'interfaces/cms/content';
import styles from './SingleLogo.module.scss';

function SingleLogo({
  logo,
  content,
  contentColor,
  itemsPerRow = 6,
  url,
  pageSlug,
  totalLogos = 6,
  linkRel,
}: SingleLogoProps) {
  const isLogo = itemsPerRow === 6 || itemsPerRow === 4;

  const calculateColSpan = () => {
    const colSpans = {
      6: 'md:col-span-2',
      4: 'md:col-span-3',
      3: 'md:col-span-4',
      2: 'md:col-span-6',
    } as Record<number, string>;

    return colSpans[itemsPerRow] || 'md:col-span-12';
  };

  const calculateColSpanSmall = () => {
    if (logo && (itemsPerRow === 6 || itemsPerRow === 4)) {
      const colSpans = {
        1: 'col-span-12',
        2: 'col-span-6',
      } as Record<number, string>;

      return colSpans[totalLogos] || 'col-span-4';
    }
    return 'col-span-12';
  };

  const renderContent = (
    <div
      className={clsx(
        url || pageSlug ? styles.singleLogo : styles.singleLogo__nolink,
        'mx-auto',
        isLogo && 'lg:w-[10rem] lg:h-[6.0625rem]'
      )}
    >
      {logo?.desktopImage ? (
        <ResponsiveImage
          src={logo.desktopImage}
          srcMobile={logo.mobileImage || logo.desktopImage}
          artDirection={logo.artDirection}
          width={logo.desktopImage.width}
          height={logo.desktopImage.height}
          sizes="(max-width: 767px) 100vw, (max-width:1200px) 50vw, 33vw"
        />
      ) : (
        <Markdown
          md={content || ''}
          textAlign="center"
          textColor={contentColor}
        />
      )}
    </div>
  );

  return (
    <div
      className={clsx(
        calculateColSpanSmall(),
        calculateColSpan(),
        'overflow-hidden rounded-2xl relative',
        !isLogo && 'shadow-[0_0.4375rem_0.9375rem_rgba(42,49,203,0.1)]'
      )}
    >
      {url || pageSlug ? (
        <Link href={pageSlug || url} isExternalLink={!!url} rel={linkRel}>
          {renderContent}
        </Link>
      ) : (
        renderContent
      )}
    </div>
  );
}

export default SingleLogo;
